import React from 'react'
import TextWithDots from '../../common/TextWithDots/TextWithDots'
import { LocalizedLink } from 'gatsby-theme-i18n'
import clsx from 'clsx'
import { companyAddress } from '../../../consts'
import SocialMedia from '../../common/SocialMedia/SocialMedia'
import PropTypes from 'prop-types'
import ContentContainer from '../../common/Layout/ContentContainer'
import FadeInUpAnimation from '../../common/FadeInUpAnimation/FadeInUpAnimation'
import UnderlineEffect from '../../common/UnderlineEffect'

const { companyMail } = companyAddress
const currentYear = new Date().getFullYear()

const getFooterClassNames = (invertBgColor) =>
  clsx(
    'relative article-padding',
    invertBgColor ? 'bg-black text-white' : 'bg-brand-secondary text-black'
  )

const Footer = ({ invertBgColor, id, content, mail, ...props }) => (
  <footer
    id={id}
    className={getFooterClassNames(invertBgColor)}
    {...props}
  >
    <ContentContainer>
      <div className='mb-28 mt-28 lg:w-3/4 xl:grid xl:w-full xl:grid-cols-6 xl:gap-5 xl:mb-36'>
        <FadeInUpAnimation className='mb-12 col-span-2'>
          <TextWithDots>work with us</TextWithDots>
        </FadeInUpAnimation>
        <div className='col-span-4'>
          <FadeInUpAnimation
            className='text-2xl leading-8 font-light lg:text-5xl lg:leading-snug'
            as='p'
          >
            {content}
          </FadeInUpAnimation>
          <FadeInUpAnimation
            className='inline-block mt-12 text-2xl leading-8 font-light lg:text-5xl lg:leading-snug lg:mt-20 text-brand-primary'
            as='a'
            href={`mailto:${mail || companyMail}`}
          >
            <UnderlineEffect>{mail || companyMail}</UnderlineEffect>
          </FadeInUpAnimation>
        </div>
      </div>
      <div className='pt-10 border-t border-line-grey grid grid-cols-5 lg:mb-5 xl:grid-cols-6'>
        <FadeInUpAnimation className='flex flex-col justify-between col-span-3 lg:flex-row xl:col-span-4 xl:grid xl:grid-cols-4'>
          <address className='mb-6 not-italic text-sm leading-5 lg:text-xl lg:leading-7 xl:col-span-2'>
            <a
              className='block max-w-fit whitespace-nowrap'
              href={`mailto:${companyMail}`}
            >
              {companyMail}
            </a>
          </address>
          <div className='my-6 lg:my-0 text-sm leading-5 lg:text-xl lg:leading-7'>
            <LocalizedLink
              className={`block max-w-fit whitespace-nowrap ${
                invertBgColor ? 'text-white' : 'text-black'
              }`}
              to='/privacy'
            >
              Privacy Policy
            </LocalizedLink>
          </div>
        </FadeInUpAnimation>
        <div className='col-end-7 flex justify-end items-start'>
          <SocialMedia
            iconClassName={
              invertBgColor ? 'hover:text-white' : 'hover:text-black'
            }
          />
        </div>
      </div>
    </ContentContainer>
    <div className='absolute left-0 bottom-0 flex items-center w-full h-16 bg-brand-primary'>
      <ContentContainer
        as='span'
        className='w-full text-black'
      >
        ©{currentYear} the.good.code; All rights reserved.
      </ContentContainer>
    </div>
  </footer>
)

export default Footer

Footer.propTypes = {
  invertBgColor: PropTypes.bool,
  id: PropTypes.string,
  content: PropTypes.string,
  mail: PropTypes.string
}

Footer.defaultProps = {
  invertBgColor: false,
  id: '',
  content:
    'We are eager to learn about your organization and to ' +
    'help you achieve a well defined and consistent strategy.',
  mail: ''
}
