import React from 'react'
import Accordion from './Accordion'
import PropTypes from 'prop-types'
import useLineAnimation from '../../../common/hooks/useLineAnimation'

const Accordions = ({ points, className }) => {
  const accordionsRef = useLineAnimation()

  return (
    <div
      ref={accordionsRef}
      className={className}
    >
      {points.map(({ title, info }, idx) => (
        <Accordion
          key={idx}
          id={idx + 1}
          title={title}
        >
          {info}
        </Accordion>
      ))}
    </div>
  )
}

export default Accordions

Accordions.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      info: PropTypes.string
    })
  ),
  className: PropTypes.string
}

Accordions.defaultProps = {
  points: [],
  className: ''
}
