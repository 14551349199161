import React from 'react'
import PropTypes from 'prop-types'

const CooperatingContent = ({ content }) => (
  <div className='border-t border-line-grey'>
    <h3 className='flex gap-2 my-5 2xl:text-5xl text-2xl lg:leading-loose leading-2'>
      <span className='text-brand-primary'>{content.id}.</span>
      <span>{content.title}</span>
    </h3>
    <p className='text-lg leading-6 font-normal lg:text-xl lg:leading-7'>
      {content.description}
    </p>
  </div>
)

export default CooperatingContent

CooperatingContent.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired
}
