import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import clsx from 'clsx'
import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "logos" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(width: 300, formats: [AUTO, WEBP, AVIF], quality: 80)
        }
      }
    }
  }
`

const marqueeGroupClassNames = clsx(
  'shrink-0 flex justify-around items-center h-full min-w-full lg:space-x-4 space-x-20 animate-scroll-logos group-hover:pause'
)

const Companies = () => {
  const data = useStaticQuery(query)
  const companies = [
    {
      name: 'Coboworx',
      link: 'https://www.coboworx.com/',
      logo: data.allFile.nodes.find((node) => node.name === 'coboworx')
    },
    {
      name: 'Elfsquad',
      link: 'https://www.elfsquad.io/',
      logo: data.allFile.nodes.find((node) => node.name === 'elfsquad')
    },
    {
      name: 'SenPrima',
      link: 'https://senprima.de/',
      logo: data.allFile.nodes.find((node) => node.name === 'senPrima')
    },
    {
      name: 'INH',
      link: 'https://www.inh.eu/',
      logo: data.allFile.nodes.find((node) => node.name === 'INH')
    }
  ]

  return (
    <div className='lg:my-24 mb-16 flex lg:space-x-4 space-x-20 overflow-hidden select-none h-36 lg:h-56 group'>
      <ul className={marqueeGroupClassNames}>
        {companies.map((company, idx) => (
          <li
            key={idx}
            className='h-full'
          >
            <div className='grid place-items-center h-full aspect-video'>
              <a
                className='h-full text-center aspect-video py-5 '
                href={company.link}
              >
                <GatsbyImage
                  className='h-full mx-10 w-56 lg:w-fit'
                  image={company.logo.childImageSharp.gatsbyImageData}
                  alt={company.name}
                  loading='lazy'
                  width={300}
                  height={300}
                />
              </a>
            </div>
          </li>
        ))}
      </ul>
      <ul className={marqueeGroupClassNames}>
        {companies.map((company, idx) => (
          <li
            className='h-full'
            key={idx}
          >
            <div className='grid place-items-center h-full aspect-video'>
              <a
                className='h-full text-center aspect-video py-5 '
                href={company.link}
              >
                <GatsbyImage
                  className='h-full mx-10 w-56 lg:w-fit'
                  image={company.logo.childImageSharp.gatsbyImageData}
                  alt={company.name}
                  loading='lazy'
                  width={300}
                  height={300}
                />
              </a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Companies
