import React from 'react'
import PropTypes from 'prop-types'
import FadeInUpAnimation from '../../../common/FadeInUpAnimation/FadeInUpAnimation'

const AccordionLabel = ({ id, title, expanded }) => (
  <FadeInUpAnimation className='flex'>
    <span
      className={`inline-block text-2xl leading-8 font-light lg:text-5xl lg:leading-snug mr-2 lg:mr-0 lg:w-16 text-brand-primary after:content-["."]`}
    >
      {id < 10 ? `0${id}` : id}
    </span>
    <span>{title}</span>
  </FadeInUpAnimation>
)

export default AccordionLabel

AccordionLabel.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  expanded: PropTypes.bool
}

AccordionLabel.defaultProps = {
  title: ''
}
