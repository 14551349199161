export const whatWeDoPoints = [
  {
    title: 'Tech and Strategy Consulting',
    info:
      'From strategy to front and backend development,' +
      'to UX design, to data engineering, and product innovation we ' +
      'offer consultation in all areas of technology landscape.'
  },
  {
    title: 'Software Development',
    info:
      'With wide-ranging experience in Industry 4.0, customer-facing and B2B solutions, ' +
      'our core focus is on the creation of web apps and platforms based on JS technology (React, Node.js)'
  },
  {
    title: 'Cloud Engineering',
    info: 'We create and manage corporate Cloud environments, analyse and plan migrations and oversee governance processes and automation (IaaC).'
  },
  {
    title: 'Data Engineering',
    info:
      'Our services include building data pipelines for large volumes (real-time and' +
      'batch processing), development of automated data quality checks/unification and implementation of ' +
      'complex data models.'
  },
  {
    title: 'Innovation Teams',
    info: 'Our startups experienced teams deliver digitised business processes and create fully-realised digital products based on cutting edge AI technology.'
  }
]

export const toolkit = [
  'Microsoft Azure',
  'Google Cloud',
  'Amazon WS',
  'Angular JS',
  'JS+CSS+HTML',
  'React',
  'Angular',
  'Node',
  'Python',
  'Apache Spark',
  'Github'
]
