import React from 'react'
import OpenCloseIcon from '../../../common/Icons/OpenCloseIcon'
import PropTypes from 'prop-types'

const AccordionBtn = ({ id, onExpand, expanded, children }) => (
  <button
    className={`w-full flex justify-between items-center mb-5 cursor-none`}
    onClick={() => onExpand(!expanded)}
    aria-expanded={expanded}
    id={`accordion-${id}`}
    aria-controls={`panel-${id}`}
  >
    <h3 className='text-left text-2xl leading-8 font-light lg:text-5xl lg:leading-snug'>
      {children}
    </h3>
    <OpenCloseIcon
      className={`w-12 lg:w-16`}
      active={expanded}
    />
  </button>
)

export default AccordionBtn

AccordionBtn.propTypes = {
  id: PropTypes.number,
  onExpand: PropTypes.func,
  expanded: PropTypes.bool,
  children: PropTypes.node
}

AccordionBtn.defaultProps = {
  expanded: false,
  children: ''
}
