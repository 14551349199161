export const cooperatingContentText = [
  {
    id: '01',
    title: 'Team Augmentation',
    description:
      'With the ability to act as an internal resource, our skilled engineers can provide in-house software development support for your team. Ideally placed to strengthen your teams during long-term collaborations.'
  },
  {
    id: '02',
    title: 'Project Based',
    description:
      'We provide complete team and skills necessary to run the whole project, including every stage in software development lifecycle and management. Customer engagement is necessary only on requirements and specification part.'
  },
  {
    id: '03',
    title: 'Consulting',
    description:
      'Our consultants can quickly get an overview of your needs, solve issues and remove elements which impede progress. We can provide flexible billing based on an hourly rate.'
  }
]
