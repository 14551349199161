import React from 'react'
import ContentContainer from '../../../common/Layout/ContentContainer'
import CooperatingContent from './CooperatingContent'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import FadeInUpAnimation from '../../../common/FadeInUpAnimation/FadeInUpAnimation'
import TextWithDots from '../../../common/TextWithDots/TextWithDots'
import { cooperatingContentText } from './consts'

const query = graphql`
  query {
    backgroundImage: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "home/cooperatingModels.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`

const CooperatingModels = () => {
  const { backgroundImage } = useStaticQuery(query)
  const image = getImage(backgroundImage)
  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage
      Tag='section'
      className='text-white'
      preserveStackingContext
      {...bgImage}
    >
      <ContentContainer>
        <FadeInUpAnimation>
          <TextWithDots className='py-20 sm:block hidden'>
            cooperating models
          </TextWithDots>
          <h2 className='sm:hidden flex flex-col py-10 font-normal'>
            <span>cooperating</span>
            <span className='after:content-[";"] after:text-brand-primary'>
              models
            </span>
          </h2>
        </FadeInUpAnimation>
        <div className='grid xl:grid-cols-3 gap-5 space-y-20 lg:space-y-0 pb-32'>
          {cooperatingContentText.map((singleContent, index) => (
            <FadeInUpAnimation
              key={index}
              delay={index / 4}
            >
              <CooperatingContent content={singleContent} />
            </FadeInUpAnimation>
          ))}
        </div>
      </ContentContainer>
    </BackgroundImage>
  )
}

export default CooperatingModels
