import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AccordionBtn from './AccordionBtn'
import AccordionLabel from './AccordionLabel'

const Accordion = ({ id, title, children }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className='relative mb-5 last:mb-0 overflow-hidden cursor-none'>
      <AccordionBtn
        id={id}
        onExpand={setExpanded}
        expanded={expanded}
      >
        <AccordionLabel
          id={id}
          title={title}
          expanded={expanded}
        />
      </AccordionBtn>
      <p
        className={`lg:ml-16 max-h-0 overflow-hidden transition-all ease-in-out duration-500 
          ${expanded ? 'max-h-[500px] mb-10' : 'mb-0'}`}
        role='region'
        id={`panel-${id}`}
        aria-labelledby={`accordion-${id}`}
      >
        {children}
      </p>
      <span className='line block w-full h-[1px] bg-line-grey' />
    </div>
  )
}

export default Accordion

Accordion.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  children: PropTypes.node
}

Accordion.defaultProps = {
  title: '',
  info: ''
}
