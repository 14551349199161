import React from 'react'
import PropTypes from 'prop-types'
import Footer from '../Footer/Footer'
import CommonLayout from '../../common/Layout/Layout'
import CommonHeader from '../../common/Header/Header'
import { links } from './links'
import CookieConsentBanner from './CookieConsentBanner/CookieConsentBanner'

const Layout = ({ children, indexPage, title, description, content, mail }) => (
  <CommonLayout
    header={<CommonHeader links={links} />}
    footer={
      <Footer
        id='main-footer'
        invertBgColor={indexPage}
        content={content}
        mail={mail}
      />
    }
    cookies={<CookieConsentBanner />}
    title={title}
    description={description}
  >
    {children}
  </CommonLayout>
)

export default Layout

Layout.propTypes = {
  indexPage: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
  mail: PropTypes.string
}

Layout.defaultProps = {
  indexPage: false
}
