import React from 'react'
import Layout from '../components/en/Layout/Layout'
import Home from '../components/en/Home/Home'

const IndexPage = () => (
  <Layout
    indexPage
    title='the.good.code; | High-Quality IT Services - Improve Your Digital Presence'
    description='the.good.code; provides high-quality IT services, tailored to your business needs. Contact us today to learn how we can help you improve your digital presence.'
  >
    <Home />
  </Layout>
)

export default IndexPage
