import React from 'react'
import ContentContainer from '../../common/Layout/ContentContainer'
import { StaticImage } from 'gatsby-plugin-image'
import WhoWeAre from './WhoWeAre'
import WhatWeDo from './WhatWeDo/WhatWeDo'
import Companies from './Companies/Companies'
import FeaturedWork from './FeaturedWork/FeaturedWork'
import Testimonials from './Testimonials/Testimonials'
import CooperatingModels from './CooperatingModels/CooperatingModels'
import FadeInUpAnimation from '../../common/FadeInUpAnimation/FadeInUpAnimation'
import Parallax from '../../common/ParallaxAnimation/Parallax'
import useProjectsAnimation from '../../common/hooks/useProjectsAnimation'
import SectionHeader from '../../common/Header/SectionHeader'

const Home = () => {
  const { animationContainerRef, triggerRef } = useProjectsAnimation()

  return (
    <section>
      <SectionHeader
        className='min-h-[260px] sm:max-lg:min-h-[135px]'
        text='Everything starts with a good '
        wordsToType={['code', 'team', 'idea']}
        loop={0}
      />
      <ContentContainer>
        <FadeInUpAnimation className='opacity-25'>
          <Parallax className='mb-20'>
            <StaticImage
              className='rounded-2xl md:h-full'
              src='../../../images/home/img1.jpeg'
              alt='people working at computers'
              loading='eager'
              layout='fullWidth'
              placeholder='tracedSVG'
              quality={80}
            />
          </Parallax>
        </FadeInUpAnimation>
      </ContentContainer>
      <WhoWeAre />
      <Companies />
      <WhatWeDo />
      <div ref={animationContainerRef}>
        <CooperatingModels />
        <FeaturedWork ref={triggerRef} />
        <Testimonials />
      </div>
    </section>
  )
}

export default Home
