import React, { useEffect, useRef } from 'react'
import ArrowRightIcon from '../../../common/Icons/ArrowRightIcon'
import PropTypes from 'prop-types'
import { gsap } from '../../../gsap'
import FadeInUpAnimation from '../../../common/FadeInUpAnimation/FadeInUpAnimation'
import useMatchesBreakpoint from '../../../common/hooks/useMatchesBreakpoint'
import clsx from 'clsx'

const toolContainerClassNames = (containersNumber, idx) =>
  clsx(
    'tool col-span-4 sm:col-span-2 lg:col-span-1 py-5 border-b border-line-grey',
    idx < containersNumber && 'border-t'
  )

const Toolkit = ({ toolkit, className }) => {
  const toolkitRef = useRef(null)

  const matches = useMatchesBreakpoint()

  let toolContainersWithBorderTop = 1

  if (matches('lg')) {
    toolContainersWithBorderTop = 4
  } else if (matches('sm')) {
    toolContainersWithBorderTop = 2
  }

  useEffect(() => {
    const ctx = gsap.context(() => {
      const toolkitArr = gsap.utils.toArray('.tool')

      toolkitArr.forEach((tool) => {
        gsap.from(tool, {
          opacity: 0,
          y: 100,
          scrollTrigger: {
            trigger: tool,
            start: 'top 80%'
          }
        })
      })
    }, toolkitRef)

    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <div className={className}>
      <FadeInUpAnimation
        className='mb-12 text-2xl leading-8 font-light lg:text-5xl lg:leading-snug'
        as='h3'
      >
        Our toolkit
      </FadeInUpAnimation>
      <div
        ref={toolkitRef}
        className='grid grid-cols-4 gap-x-5'
      >
        {toolkit.map((tool, idx) => (
          <div
            key={idx}
            className={toolContainerClassNames(
              toolContainersWithBorderTop,
              idx
            )}
          >
            <ArrowRightIcon className='inline w-3 mr-3 text-brand-primary lg:w-5' />
            <span>{tool}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Toolkit

Toolkit.propTypes = {
  toolkit: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string
}

Toolkit.defaultProps = {
  toolkit: [],
  className: ''
}
