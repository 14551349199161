import React from 'react'
import ContentContainer from '../../../common/Layout/ContentContainer'
import TextWithDots from '../../../common/TextWithDots/TextWithDots'

const PrivacyPolicyModalContent = () => (
  <ContentContainer>
    <div className='mb-30 space-y-10 text-sm'>
      <TextWithDots>Privacy Policy</TextWithDots>
      <p>
        This Privacy Policy describes how we collect, use, and disclose
        information from visitors to our website. By using our website, you
        agree to the collection and use of information in accordance with this
        Privacy Policy.
      </p>
      <h4>Information Collection and Use</h4>
      <p>
        We use Google Analytics to collect information about how visitors use
        our website. This includes information such as your device's IP address,
        browser type, and pages visited. We use this information to analyze
        website traffic and improve the user experience. Google Analytics
        collects this information using cookies, which are small data files
        stored on your device.
      </p>
      <p className='mt-5'>
        We may also collect personal information that you provide to us, such as
        your name and email address, if you choose to sign up for our newsletter
        or contact us through our website.
      </p>
      <h4>Information Sharing</h4>
      <p>
        We may share your personal information with third-party service
        providers who perform services on our behalf, such as email marketing or
        website hosting. These service providers are not authorized to use or
        disclose your personal information for any purpose other than providing
        services to us.
      </p>
      <p>
        We may also disclose your personal information if required by law or if
        we believe that such action is necessary to comply with legal
        requirements, protect our rights or the rights of others, or to prevent
        fraud or other illegal activities.
      </p>
      <h4>Information Security</h4>
      <p>
        We take reasonable measures to protect your personal information from
        unauthorized access, disclosure, or use. However, no method of
        transmission over the internet or electronic storage is 100% secure, so
        we cannot guarantee absolute security.
      </p>
      <h4>Links to Other Websites</h4>
      <p>
        Our website may contain links to other websites that are not owned or
        controlled by us. We are not responsible for the privacy practices or
        content of these websites.
      </p>
      <h4>Children's Privacy</h4>
      <p>
        Our website is not intended for use by children under the age of 13. We
        do not knowingly collect personal information from children under the
        age of 13. If we become aware that we have collected personal
        information from a child under the age of 13, we will take steps to
        delete that information.
      </p>
      <h4>Changes to this Privacy Policy</h4>
      <p>
        We may update this Privacy Policy from time to time. The updated Privacy
        Policy will be posted on our website, and the date of the latest
        revision will be indicated at the top of the Privacy Policy.
      </p>
      <h4>Contact Us</h4>
      <p className='group  transition-all duration-300 ease-in-out'>
        If you have any questions about this Privacy Policy, please contact us
        at{' '}
        <a
          href='mailto:contacts@thegoodcode.io'
          className='text-brand-primary bg-left-bottom bg-gradient-to-r from-brand-primary to-brand-primary bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'
        >
          contact@thegoodcode.io.
        </a>
      </p>
    </div>
  </ContentContainer>
)

export default PrivacyPolicyModalContent
